import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import 'assets/theme.scss';
import 'assets/style/index.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'leaflet/dist/leaflet.css';
import 'assets/style/mondialrelay.css';
import App from 'App';
import reportWebVitals from 'reportWebVitals';
import 'helpers/i18n';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer, toast } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import LoadingScreenContextProvider from 'helpers/LoadingScreenContext';
import { persistor, store } from 'logic';
import { CookiesProvider } from 'react-cookie';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const emotionCache = createCache({
  key: 'emotion-cache-key',
});

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="">
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <BrowserRouter>
            <LoadingScreenContextProvider>
              <CookiesProvider>
                <GoogleReCaptchaProvider
                  reCaptchaKey="6LdouyUoAAAAAMplyvLUsonCoQCKzZxt8lQ6NS30"
                  useRecaptchaNet="false"
                  useEnterprise="false"
                  scriptProps={{
                    async: true,
                    defer: true,
                    appendTo: 'head',
                  }}
                >
                  <CacheProvider value={emotionCache}>
                    <App />
                  </CacheProvider>
                </GoogleReCaptchaProvider>
              </CookiesProvider>
            </LoadingScreenContextProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </Suspense>
    <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log)) abcefgh
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
